import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ReferYourFriendAction } from "redux-container/refer-your-friend/ReferYourFriendRedux";
import { call, put } from "redux-saga/effects";

export function* referYourFriendSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addReferral, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ReferYourFriendAction.referYourFriendSuccess(response.data));
    } else {
      yield put(ReferYourFriendAction.referYourFriendFailure(response.data));
    }
  } catch (error) {
    yield put(ReferYourFriendAction.referYourFriendFailure(error));
  }
}
