import Snackbar from "@mui/material/Snackbar";
import { Constants } from "constants/Constants";
import {
  Box,
  styled,
} from "@mui/material";

interface ISnackbarProps {
  shouldOpen?: boolean;
  message?: string;
  isSuccess?: boolean;
  closeHandler?: (e?: any) => void;
}

const SnackbarStyledWrapper = styled(Box)<ISnackbarProps>`
  & .MuiPaper-root {
    border-radius: 0px;
    border-left: 5px solid ${({ isSuccess }) => (isSuccess ? "green" : "red")};
  }
`;

const MuiSnackbar = ({
  shouldOpen,
  message,
  isSuccess,
  closeHandler,
}: ISnackbarProps) => {
  const handleClose = () => {
    closeHandler && closeHandler();
  };

  return (
    <SnackbarStyledWrapper isSuccess={isSuccess}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={shouldOpen}
        onClose={handleClose}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={Constants.SNACKBAR_DURATION}
      />
    </SnackbarStyledWrapper>
  );
};

export default MuiSnackbar;
