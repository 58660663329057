import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";

export function* insightSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getInsightDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InsightAction.getInsightsSuccess(response.data));
    } else {
      yield put(InsightAction.getInsightsFailure(response.data));
    }
  } catch (error) {
    yield put(InsightAction.getInsightsFailure(error));
  }
}
