import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  isLoading: false,
  insightsData: [],
  insightDetailIndex: null,
  isDialogOpen: false,
};

const { Types, Creators } = createActions({
  getInsightsRequest: ["data"],
  getInsightsSuccess: ["response"],
  getInsightsFailure: ["error"],
  setInsightsState: ["key", "value"],
});

export const InsightsTypes = Types;
export const InsightAction = Creators;

export const getInsightsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getInsightsSuccess = (state: any, action: any) => {
  const response = action.response;
  return {
    ...state,
    isLoading: false,
    insightsData: response?.data,
  };
};

export const getInsightsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    insights: [],
  };
};

export const setInsightsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INSIGHTS_REQUEST]: getInsightsRequest,
  [Types.GET_INSIGHTS_SUCCESS]: getInsightsSuccess,
  [Types.GET_INSIGHTS_FAILURE]: getInsightsFailure,
  [Types.SET_INSIGHTS_STATE]: setInsightsState,
});
