import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { TestimonialsActions } from "../../redux-container/testimonials/TestimonialsRedux";

export function* getTestimonials(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getTestimonials, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TestimonialsActions.getTestimonialsSuccess(response.data));
    } else {
      yield put(TestimonialsActions.getTestimonialsFailure(response.data));
    }
  } catch (error) {
    yield put(TestimonialsActions.getTestimonialsFailure(error));
  }
}
