import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPrivacyPolicySuccess: ["response"],
  getPrivacyPolicyFailure: ["error"],
  getPrivacyPolicyRequest: ["pageType"],
});

export const PrivacyPolicyTypes = Types;

export const PrivacyPolicyAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  privacyPolicy: {},
  isDialogOpen: true,
};

export const getPrivacyPolicyRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPrivacyPolicySucces = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      privacyPolicy: response.data.page,
      isLoading: false,
    };
  }
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRIVACY_POLICY_REQUEST]: getPrivacyPolicyRequest,
  [Types.GET_PRIVACY_POLICY_SUCCESS]: getPrivacyPolicySucces,
});
