import { Constants } from "constants/Constants";
import { ContactTypeEnum } from "enumerations/ContactTypeEnum";
import { VerificationStatusTypeEnum } from "enumerations/VerificationStatusTypeEnum";
import { createReducer, createActions } from "reduxsauce";
import { setItemToLocalStorage } from "utils/LocalStorageUtils";

const { Types, Creators } = createActions({
  verifyOtpSuccess: ["response"],
  verifyOtpFailure: ["error"],
  verifyOtpRequest: ["data"],

  setVerifyOtpState: ["key", "value"],
  resetVerifyOtpState: [""],
});

export const VerifyOtpTypes = Types;

export default Creators; // check creators logic in changepassword admin app

export const INITIAL_STATE = {
  error: null,
  isLoggedIn: false,
  isLoading: false,
  shouldShowNewUserName: false,
  isOtpVerified: false,
  message: "",
  tempCode: null,
};

export const verifyOtpRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const verifyOtpSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    setItemToLocalStorage(Constants.AUTHORIZATION_TOKEN, response?.token);
    setItemToLocalStorage(Constants.LOGGED_IN_USER, response?.user);

    return {
      ...state,
      error: null,
      isLoading: false,
      isOtpVerified: true,
      isLoggedIn:
        response.user.firstName === "" ||
        response.user.firstName === null ||
        (response.user.verificationStatus ===
          VerificationStatusTypeEnum.Unverified &&
          response.user.contactType === ContactTypeEnum.PreLead)
          ? false
          : true,
      message: response.message
        ? response.message
        : "OTP Verified Successfully",
      shouldShowNewUserName:
        response.user.firstName === "" ||
        response.user.firstName === null ||
        (response.user.verificationStatus ===
          VerificationStatusTypeEnum.Unverified &&
          (response.user.contactType === ContactTypeEnum.PreLead ||
            response.user.contactType === null))
          ? true
          : false,
    };
  }
};

export const verifyOtpFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setVerifyOtpState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const resetVerifyOtpState = (state: any, action: any) => {
  return {
    ...state,
    error: null,
    isLoggedIn: false,
    isLoading: false,
    shouldShowNewUserName: false,
    isOtpVerified: false,
    message: "",
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.VERIFY_OTP_SUCCESS]: verifyOtpSuccess,
  [Types.VERIFY_OTP_FAILURE]: verifyOtpFailure,
  [Types.VERIFY_OTP_REQUEST]: verifyOtpRequest,

  [Types.SET_VERIFY_OTP_STATE]: setVerifyOtpState,
  [Types.RESET_VERIFY_OTP_STATE]: resetVerifyOtpState,
});
