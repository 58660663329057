import { createTheme, responsiveFontSizes } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

let HoABLTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 690,
      md: 890,
      lg: 1200,
      xl: 1980,
    },
  },
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#676ac0",
      light: "#f3f4ff",
      dark: "#494a67",
    },
    secondary: {
      main: "#3f416d",
      light: "#cdceed",
      dark: "#32304f",
    },
    text: {
      primary: "#17171c",
      secondary: "#1c1c1c",
      disabled: "#9192a0",
    },
    grey: {
      50: "#e4e4e9",
      100: "#f7f7f7",
      200: "#565661",
      300: "#ffffff4a",
      400: "#707070",
      500: "#82ffffff",
      600: "#ebebf0",
      700: "#d8d8d8",
      800: "#9d9eb1",
      900: "#878790",
      A100: "#969696",
      A200: "#7b7878",
      A400: "#313131",
      A700: "#545566",
    },
    gradient: {
      blackShades: "linear-gradient(110deg, #505064 5%, #343445 94%)",
      lightBlueShades: "linear-gradient(to bottom, #f0f0ff, #e7e8ff)",
      blueShades:
        "linear-gradient(to left, #32369f 50%, #212365 -6%, #525252 -6%)",
      yellowShades: "linear-gradient(105deg, #faf4d1 6%, #f4e8a9 92%)",
    },
    success: {
      main: "#44b75d",
      light: "#5ee67c",
      dark: "#21b342",
    },
    warning: {
      main: "#fff4bc",
      dark: "#ccab00",
      light: "#fff9db",
      contrastText: "#796f3f",
      A100: "#7a7458",
      A200: "#928336",
      A400: "#83773d",
    },
    error: {
      main: "#ff485a",
      dark: "#f45353",
      light: "#c94d4d",
      contrastText: "#fa6c6c",
    },
    background: {
      paper: "#f2f2f2",
      default: "#ffffff",
    },
    divider: "#b6b3d0",
    action: {
      disabled: "#d3d3d9",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: '"Jost-Regular", "Jost-Medium", "Jost-Italic"',
    fontSize: 10,
  },
  shape: {
    borderRadius: 7,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "large",
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        disableTouchRipple: true,
        variant: "outlined",
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "#17171c",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        size: "large",
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        loadingPosition: "start",
        variant: "contained",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "default",
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
        margin: "none",
        size: "medium",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        margin: "none",
        size: "medium",
        IconComponent: ExpandMoreIcon,
        MenuProps: {
          PaperProps: { sx: { maxHeight: 250 } },
          MenuListProps: { sx: { ".MuiMenuItem-root": { minHeight: 35 } } },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiLink: {
      defaultProps: {
        color: "inherit",
        underline: "none",
      },
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "square",
        sizes: "small",
        alt: "icons",
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        position: "start",
      },
    },
    MuiDrawer: {
      defaultProps: {
        variant: "persistent",
      },
    },
  },
});

export default HoABLTheme = responsiveFontSizes(HoABLTheme);
