import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getInvestmentDetailsSuccess: ["response"],
  getInvestmentDetailsFailure: ["error"],
  getInvestmentDetailsRequest: ["data"],

  addToWatchlistSuccess: ["response"],
  addToWatchlistFailure: ["error"],
  addToWatchlistRequest: ["data"],

  getAllWatchlistSuccess: ["response"],
  getAllWatchlistFailure: ["error"],
  getAllWatchlistRequest: ["data"],

  deleteWatchlistSuccess: ["response"],
  deleteWatchlistFailure: ["error"],
  deleteWatchlistRequest: ["data"],

  setInvestmentDetailsState: ["key", "value"],
});

export const InvestmentDetailsTypes = Types;

export const InvestmentDetailsActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isWatchlistLoading: false,
  investmentDetails: null,
  isResponseDialogOpen: false,
  isSimiliarInvestmentDrawerOpen: false,
  watchlists: [],
  isWatchlisted: false,
  message: null,
};

export const getInvestmentDetailsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    message: null,
  };
};

export const getInvestmentDetailsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      investmentDetails: response.data,
      isLoading: false,
      message: null,
    };
  }
};

export const getInvestmentDetailsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message || "Something went wrong.",
    message: null,
  };
};

// add to watchlist
export const addToWatchlistRequest = (state: any) => {
  return {
    ...state,
    isWatchlistLoading: true,
    message: null,
  };
};

export const addToWatchlistSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isWatchlistLoading: false,
      isWatchlisted: true,
      message: response.message,
    };
  }
};

export const addToWatchlistFailure = (state: any, action: any) => {
  return {
    ...state,
    isWatchlistLoading: false,
    error: action.error.message || "Something went wrong.",
    message: null,
  };
};

// get all watchlists
export const getAllWatchlistRequest = (state: any) => {
  return {
    ...state,
    isWatchlistLoading: true,
    message: null,
  };
};

export const getAllWatchlistSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      watchlists: response.data,
      isWatchlistLoading: false,
      message: null,
    };
  }
};

export const getAllWatchlistFailure = (state: any, action: any) => {
  return {
    ...state,
    isWatchlistLoading: false,
    error: action.error.message || "Something went wrong.",
    message: null,
  };
};

// delete watchlist
export const deleteWatchlistRequest = (state: any) => {
  return {
    ...state,
    isWatchlistLoading: true,
    message: null,
  };
};

export const deleteWatchlistSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isWatchlistLoading: false,
      isWatchlisted: false,
      message: response.message,
    };
  }
};

export const deleteWatchlistFailure = (state: any, action: any) => {
  return {
    ...state,
    isWatchlistLoading: false,
    error: action.error.message || "Something went wrong.",
    message: null,
  };
};

export const setInvestmentDetailsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INVESTMENT_DETAILS_REQUEST]: getInvestmentDetailsRequest,
  [Types.GET_INVESTMENT_DETAILS_SUCCESS]: getInvestmentDetailsSuccess,
  [Types.GET_INVESTMENT_DETAILS_FAILURE]: getInvestmentDetailsFailure,

  [Types.GET_ALL_WATCHLIST_REQUEST]: getAllWatchlistRequest,
  [Types.GET_ALL_WATCHLIST_SUCCESS]: getAllWatchlistSuccess,
  [Types.GET_ALL_WATCHLIST_FAILURE]: getAllWatchlistFailure,

  [Types.ADD_TO_WATCHLIST_REQUEST]: addToWatchlistRequest,
  [Types.ADD_TO_WATCHLIST_SUCCESS]: addToWatchlistSuccess,
  [Types.ADD_TO_WATCHLIST_FAILURE]: addToWatchlistFailure,

  [Types.DELETE_WATCHLIST_REQUEST]: deleteWatchlistRequest,
  [Types.DELETE_WATCHLIST_SUCCESS]: deleteWatchlistSuccess,
  [Types.DELETE_WATCHLIST_FAILURE]: deleteWatchlistFailure,

  [Types.SET_INVESTMENT_DETAILS_STATE]: setInvestmentDetailsState,
});
