import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { getWatchListAction } from "redux-container/watch-list/watchListRedux";
import { call, put } from "redux-saga/effects";

export function* getWatchListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getWatchListData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getWatchListAction.getWatchListSuccess(response.data));
    } else {
      yield put(getWatchListAction.getWatchListFailure(response.data));
    }
  } catch (error) {
    yield put(getWatchListAction.getWatchListFailure(error));
  }
}
