import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

export function* getPortfolioPreview(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPortfolioPreview, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPortfolioAction.getPortfolioPreviewSuccess(response.data));
    } else {
      yield put(getPortfolioAction.getPortfolioPreviewFailure(response.data));
    }
  } catch (error) {
    yield put(getPortfolioAction.getPortfolioPreviewFailure(error));
  }
}

export function* getPortfolioSummarySaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPortfolioSummary, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPortfolioAction.getInvestmentSummarySuccess(response.data));
    } else {
      yield put(getPortfolioAction.getInvestmentSummaryFailure(response.data));
    }
  } catch (error) {
    yield put(getPortfolioAction.getInvestmentSummaryFailure(error));
  }
}

export function* getPortfolioDetailsSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPortfolioDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPortfolioAction.getPortfolioDetailsSuccess(response.data));
    } else {
      yield put(getPortfolioAction.getPortfolioDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getPortfolioAction.getPortfolioDetailsFailure(error));
  }
}

export function* getBookingJourneyData(api: any, action: any): any {
  try {
    const response = yield call(api.getBookingJourney, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPortfolioAction.getBookingJourneySuccess(response.data));
    } else {
      yield put(getPortfolioAction.getBookingJourneyFailure(response.data));
    }
  } catch (error) {
    yield put(getPortfolioAction.getBookingJourneyFailure(error));
  }
}

export function* getProjectFaqsContent(api: any, action: any): any {
  try {
    const response = yield call(api.getProjectFaqs, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPortfolioAction.getProjectFaqsSuccess(response.data));
    } else {
      yield put(getPortfolioAction.getProjectFaqsFailure(response.data));
    }
  } catch (error) {
    yield put(getPortfolioAction.getProjectFaqsFailure(error));
  }
}
