import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../../enumerations/HttpResponseTypeEnum";
import VerifyOtpActions from "../../../redux-container/login/verify-otp/VerifyOtpRedux";

export function* verifyOtp(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.verifyOtp, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(VerifyOtpActions.verifyOtpSuccess(response.data));
    } else {
      yield put(VerifyOtpActions.verifyOtpFailure(response.data));
    }
  } catch (error) {
    yield put(VerifyOtpActions.verifyOtpFailure(error));
  }
}
