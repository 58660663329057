import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getDownloadDocDataSuccess: ["response"],
  getDownloadDocDataFailure: ["error"],
  getDownloadDocDataRequest: ["data"],

  docUploadSuccess: ["response"],
  docUploadFailure: ["error"],
  docUploadRequest: ["data"],

  setDocumentsState: ["key", "value"],
});

export const DocumentsTypes = Types;

export const DocumentsActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  downloadDocData: null,
  docUploadData: null,
  isEncodedDataLoaded : false,
  openDialog: false,
  showFlashMessage: false,
  showPDFPreview: false
};

export const getDownloadDocDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    downloadDocData: null,
    isEncodedDataLoaded: false,
    showPDFPreview: false
  };
};

export const getDownloadDocDataSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      downloadDocData: response.data,
      isLoading: false,
      isEncodedDataLoaded: true,
      showPDFPreview: true,
      pdfFor: ''
    };
  }
};

export const getDownloadDocDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action?.error?.message ? action?.error?.message : "Couldn't Fetch Data. Try Again!",
    isEncodedDataLoaded: false
  };
};

//Doc Upload
export const docUploadRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    docUploadData: null,
    openDialog: false,
    showFlashMessage: false
  };
};

export const docUploadSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      docUploadData: response.data,
      isLoading: false,
      openDialog: true,
      showFlashMessage: true
    };
  }
};

export const docUploadFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setDocumentsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DOWNLOAD_DOC_DATA_REQUEST]: getDownloadDocDataRequest,
  [Types.GET_DOWNLOAD_DOC_DATA_SUCCESS]: getDownloadDocDataSuccess,
  [Types.GET_DOWNLOAD_DOC_DATA_FAILURE]: getDownloadDocDataFailure,

  [Types.DOC_UPLOAD_REQUEST]: docUploadRequest,
  [Types.DOC_UPLOAD_SUCCESS]: docUploadSuccess,
  [Types.DOC_UPLOAD_FAILURE]: docUploadFailure,

  [Types.SET_DOCUMENTS_STATE]: setDocumentsState,
});
