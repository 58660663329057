import HoABLTheme from "./HoablTheme";
import type {} from "@mui/lab/themeAugmentation";
import HoablTheme from "./HoablTheme";

const {
  palette,
  shape,
  typography: { pxToRem },
  breakpoints,
} = HoABLTheme;

const OverridedThemes = {
  ...HoABLTheme,
  components: {
    ...HoABLTheme.components,
    MuiTypography: {
      ...HoABLTheme.components?.MuiTypography,
      styleOverrides: {
        h4: {
          [breakpoints.up("xl")]: {
            fontSize: "3rem",
          },
        },
        h5: {
          [breakpoints.up("xl")]: {
            fontSize: "2rem",
          },
          [breakpoints.down("sm")]: {
            fontSize: "1.2rem",
          },
        },
        h6: {
          [breakpoints.up("xl")]: {
            fontSize: "1.8rem",
          },
        },
        body1: {
          [breakpoints.between("lg", "xl")]: {
            fontSize: "1.3rem",
          },
          [breakpoints.up("xl")]: {
            fontSize: "1.5rem",
          },
          [breakpoints.down("sm")]: {
            fontSize: "1.1rem",
          },
        },
        body2: {
          [breakpoints.up("xl")]: {
            fontSize: "1.4rem",
          },
          [breakpoints.down("sm")]: {
            fontSize: "0.9rem",
          },
        },
        caption: {
          [breakpoints.up("xl")]: {
            fontSize: "1rem",
          },
          [breakpoints.down("sm")]: {
            fontSize: "0.7rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.black,
          padding: "1rem 0.2rem",
          fontSize: "0.7rem",
        },
        label: {
          color: palette.common.white,
        },
        deleteIcon: {
          color: palette.common.white,
          fontSize: "0.8rem",
        },
      },
    },
    MuiButton: {
      ...HoABLTheme.components?.MuiButton,
      styleOverrides: {
        root: {
          fontFamily: "Jost-Medium",
          textTransform: "capitalize",
          borderRadius: shape.borderRadius,
          ".MuiButton-endIcon > :nth-of-type(1)": {
            fontSize: pxToRem(15),
            [breakpoints.up("xl")]: {
              fontSize: pxToRem(25),
            },
          },
          [breakpoints.up("xl")]: {
            fontSize: "1.5rem",
          },
          [breakpoints.down("sm")]: {
            fontSize: pxToRem(11),
            paddingX: pxToRem(12),
          },
        },
        contained: {
          color: palette.common.white,
          backgroundColor: palette.common.black,
          "&:hover": {
            backgroundColor: palette.text.primary,
          },
        },
        containedInherit: {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.common.white,
          },
        },
        text: {
          backgroundColor: "transparent",
          color: palette.common.black,
          [breakpoints.down("sm")]: {
            padding: 0,
          },
        },
        outlinedInherit: {
          borderColor: palette.grey[100],
          backgroundColor: palette.grey[100],
          color: palette.grey[200],
          "&:hover": {
            borderColor: palette.grey[100],
            backgroundColor: palette.grey[100],
            color: palette.grey[200],
          },
        },
        textPrimary: {
          color: palette.primary.main,
          "& .MuiButton-endIcon": {
            "&:nth-of-type(1) ": {
              color: palette.common.black,
            },
          },
          [breakpoints.down("sm")]: {
            fontSize: "0.8rem",
          },
        },
        disabled: {
          color: palette.common.white,
        },
      },
    },
    MuiLoadingButton: {
      ...HoABLTheme.components?.MuiLoadingButton,
      styleOverrides: {
        root: {
          padding: `${pxToRem(8)} ${pxToRem(40)}`,
          fontFamily: "Jost-Medium",
          textTransform: "capitalize",
          [breakpoints.down("sm")]: {
            fontSize: pxToRem(12),
            padding: `${pxToRem(8)} ${pxToRem(11)}`,
          },
        },
        contained: {
          color: palette.common.white,
          backgroundColor: palette.common.black,
        },
        disabled: {
          color: palette.common.white,
        },
      },
    },
    MuiTextField: {
      ...HoABLTheme.components?.MuiTextField,
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
        },
      },
    },
    MuiInputBase: {
      ...HoABLTheme.components?.MuiInputBase,
      styleOverrides: {
        root: {
          color: palette.primary.main,
          borderRadius: shape.borderRadius,
        },
        input: {
          color: palette.common.black,
          fontFamily: "Jost-Medium",
          fontWeight: 500, // To make the text entered as Bold
        },
        disabled: {
          color: palette.grey[300],
        },
      },
    },
    MuiCheckbox: {
      ...HoABLTheme.components?.MuiCheckbox,
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    MuiSelect: {
      ...HoABLTheme.components?.MuiSelect,
      styleOverrides: {
        select: {
          borderRadius: shape.borderRadius,
          fontFamily: "Jost-Regular",
          color: palette.common.black,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          fontWeight: 500,
          "& .MuiFormControlLabel-label ": {
            color: palette.text.disabled,
            fontSize: pxToRem(13),
          },
        },
      },
    },
    MuiAvatar: {
      ...HoABLTheme.components?.MuiAvatar,
      styleOverrides: {
        img: {
          objectFit: "contain",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.common.black,
          maxWidth: 500,
        },
        arrow: {
          "&::before": {
            backgroundColor: palette.common.black,
          },
        },
      },
    },
    MuiDrawer: {
      ...HoablTheme.components?.MuiDrawer,
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root ": {
            backdropFilter: "blur(10px)",
          },
        },
        paper: {
          height: "calc(100% - 132px)",
          zIndex: 1100,
          bottom: 0,
          top: "auto",
          padding: "1rem 2rem",
          backgroundColor: "common.white",
          [breakpoints.down("sm")]: {
            height: "100%",
          },
        },
      },
    },
  },
};

export default OverridedThemes;
