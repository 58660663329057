import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { SearchActions } from "../../redux-container/search/SearchRedux";

export function* getCustomerSearchData(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getCustomerSearch, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(SearchActions.getCustomerSearchDataSuccess(response.data));
    } else {
      yield put(SearchActions.getCustomerSearchDataFailure(response.data));
    }
  } catch (error) {
    yield put(SearchActions.getCustomerSearchDataFailure(error));
  }
}

export function* getAdminSearchData(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.getAdminSearch, action.data);
      if (response.status === HttpResponseTypeEnum.Success) {
        yield put(SearchActions.getAdminSearchDataSuccess(response.data));
      } else {
        yield put(SearchActions.getAdminSearchDataFailure(response.data));
      }
    } catch (error) {
      yield put(SearchActions.getAdminSearchDataFailure(error));
    }
  }
