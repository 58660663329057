import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { ChatAction } from "../../redux-container/chat-redux/ChatRedux";
import { call, put } from "redux-saga/effects";

export function* getChat(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getChat);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ChatAction.getChatSuccess(response.data));
    } else {
      yield put(ChatAction.getChatFailure(response.data));
    }
  } catch (error) {
    yield put(ChatAction.getChatFailure(error));
  }
}

export function* getChatData(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getChatData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ChatAction.getChatDataSuccess(response.data));
    } else {
      yield put(ChatAction.getChatDataFailure(response.data));
    }
  } catch (error) {
    yield put(ChatAction.getChatDataFailure(error));
  }
}

export function* initiateChat(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.chatInitiate, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ChatAction.chatInitiateSuccess(response.data));
    } else {
      yield put(ChatAction.chatInitiateFailure(response.data));
    }
  } catch (error) {
    yield put(ChatAction.chatInitiateFailure(error));
  }
}

export function* postChatData(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.chatPostData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ChatAction.chatPostDataSuccess(response.data));
    } else {
      yield put(ChatAction.chatPostDataFailure(response.data));
    }
  } catch (error) {
    yield put(ChatAction.chatPostDataFailure(error));
  }
}
