import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  addTroubleSigninSuccess: ["response"],
  addTroubleSigninFailure: ["error"],
  addTroubleSigninRequest: ["data"],

  setTroubleSigninState: ["key", "value"],
});

export const TroubleSigninTypes = Types;

export const TroubleSigninAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isSuccess: false,
  message: null,
  isResponseDialogOpen: false,
  isDialogOpen: false,
  isCountryCodeDialogOpen: false,
  TandC: null,
};

/* Add Notifications */
export const addTroubleSigninRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const addTroubleSigninSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      message: response.message || "",
      isLoading: false,
    };
  }
};

export const addTroubleSigninFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
  };
};

export const setTroubleSigninState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_TROUBLE_SIGNIN_SUCCESS]: addTroubleSigninSuccess,
  [Types.ADD_TROUBLE_SIGNIN_FAILURE]: addTroubleSigninFailure,
  [Types.ADD_TROUBLE_SIGNIN_REQUEST]: addTroubleSigninRequest,

  [Types.SET_TROUBLE_SIGNIN_STATE]: setTroubleSigninState,
});
