import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { getUserAction } from "redux-container/user-management/UserManagementRedux";

export function* getActionItemSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUserActionItem, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getUserAction.getActionItemsSuccess(response.data));
    } else {
      yield put(getUserAction.getActionItemsFailure(response.data));
    }
  } catch (error) {
    yield put(getUserAction.getActionItemsFailure(error));
  }
}
