import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { LandSkuListActions } from "redux-container/investments/land-sku-list/LandSkuListRedux";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

export function* getLandSkuListSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.landSkuList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandSkuListActions.getLandSkuListSuccess(response.data));
    } else {
      yield put(LandSkuListActions.getLandSkuListFailure(response.data));
    }
  } catch (error) {
    yield put(LandSkuListActions.getLandSkuListFailure(error));
  }
}

export function* postVideoCallSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.videoCallApi, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandSkuListActions.postVideoCallSuccess(response.data));
    } else {
      yield put(LandSkuListActions.postVideoCallFailure(response.data));
    }
  } catch (error) {
    yield put(LandSkuListActions.postVideoCallFailure(error));
  }
}

export function* postAddInventorySaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addInventoryApi, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LandSkuListActions.postAddInventorySuccess(response.data));
    } else {
      yield put(LandSkuListActions.postAddInventoryFailure(response.data));
    }
  } catch (error) {
    yield put(LandSkuListActions.postAddInventoryFailure(error));
  }
}
