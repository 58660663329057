import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { UpdatesActions } from "../../redux-container/updates/UpdatesRedux";

export function* getUpdates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUpdates, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(UpdatesActions.getUpdatesSuccess(response.data));
    } else {
      yield put(UpdatesActions.getUpdatesFailure(response.data));
    }
  } catch (error) {
    yield put(UpdatesActions.getUpdatesFailure(error));
  }
}
