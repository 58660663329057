import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  //Rename to getChatContactsSuccess
    getChatSuccess: ["response"],
    getChatFailure: ["error"],
    getChatRequest: [""],

    // to get individual chat data
    getChatDataSuccess: ["response"],
    getChatDataFailure: ["error"],
    getChatDataRequest: ["data"],

    //chat initiate
    chatInitiateSuccess: ["response"],
    chatInitiateFailure: ["error"],
    chatInitiateRequest: ["data"],

     //chat send (post)
     chatPostDataSuccess: ["response"],
     chatPostDataFailure: ["error"],
     chatPostDataRequest: ["data"],

    setChatDataState: ["key", "value"],
})

export const ChatTypes = Types;

export const ChatAction = Creators;

export const INITIAL_STATE = {
    error: null,
    isLoading: false,
    isChatPageLoading: false,
    chat: [], // rename to contacts
    chatData: [],
    openDisplayDrawer:false,
    chatDisplayDetails: null,
    isAllowTyping: false,
    lastUpdatedDate: null,
    shouldScroll: false,
    getInitialChatDisplayData: false,
    chatJsonDetails: null
  };

  export const getChatRequest = (state: any) => {
    return {
      ...state,
      isLoading: true,
      isChatPageLoading: true,
    };
  };
  
  export const getChatSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
      return {
        ...state,
        error: null,
        chat: response.data,
        isLoading: false,
        isChatPageLoading: false,
        lastUpdatedDate: new Date().toISOString(),
      };
    }
  };
  
  export const getChatFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      isChatPageLoading: false,
      error: action.error.message,
    };
  };

  export const getChatDataRequest = (state: any) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  
  export const getChatDataSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
      return {
        ...state,
        error: null,
        chatData: response.data,
        isLoading: false,
      };
    }
  };
  
  export const getChatDataFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      error: action.error.message,
      shouldScroll: true,
      chatData: [],
    };
  };


  // chat initiate 
  
  export const chatInitiateRequest = (state: any) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  
  export const chatInitiateSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
      return {
        ...state,
        error: null,
        isLoading: false,
        chatJsonDetails: response.data
      };
    }
  };
  
  export const chatInitiateFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      error: action.error.message,
    };
  };

  //chat post data
  
  export const chatPostDataRequest = (state: any) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  
  export const chatPostDataSuccess = (state: any, action: any) => {
    const response = action.response;
    if (response) {
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    }
  };
  
  export const chatPostDataFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      error: action.error.message,
    };
  };

  export const setChatData = (state: any, action: any) => {
    const newState = {
      ...state,
    };
    newState[action.key] = action.value;
    return newState;
  };

  export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_CHAT_REQUEST]: getChatRequest,
    [Types.GET_CHAT_SUCCESS]: getChatSuccess,
    [Types.GET_CHAT_FAILURE]: getChatFailure,

    [Types.GET_CHAT_DATA_REQUEST]: getChatDataRequest,
    [Types.GET_CHAT_DATA_SUCCESS]: getChatDataSuccess,
    [Types.GET_CHAT_DATA_FAILURE]: getChatDataFailure,

    [Types.CHAT_INITIATE_REQUEST]: chatInitiateRequest,
    [Types.CHAT_INITIATE_SUCCESS]: chatInitiateSuccess,
    [Types.CHAT_INITIATE_FAILURE]: chatInitiateFailure,

    [Types.CHAT_POST_DATA_REQUEST]: chatPostDataRequest,
    [Types.CHAT_POST_DATA_SUCCESS]: chatPostDataSuccess,
    [Types.CHAT_POST_DATA_FAILURE]: chatPostDataFailure,
    
    [Types.SET_CHAT_DATA_STATE]: setChatData,
  });  
  






