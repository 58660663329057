import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { NotificationAction } from "../../redux-container/notifications/NotificationsRedux";
import { call, put } from "redux-saga/effects";

export function* getNotifications(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getNotifications);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NotificationAction.getNotificationsSuccess(response.data));
    } else {
      yield put(NotificationAction.getNotificationsFailure(response.data));
    }
  } catch (error) {
    yield put(NotificationAction.getNotificationsFailure(error));
  }
}

export function* updateNotificationStatus(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateNotificationStatus, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        NotificationAction.updateNotificationStatusSuccess(response.data)
      );
    } else {
      yield put(
        NotificationAction.updateNotificationStatusFailure(response.data)
      );
    }
  } catch (error) {
    yield put(NotificationAction.updateNotificationStatusFailure(error));
  }
}
