import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getUpdatesSuccess: ["response"],
  getUpdatesFailure: ["error"],
  getUpdatesRequest: ["data"],

  setUpdatesState: ["key", "value"],
});

export const UpdatesTypes = Types;

export const UpdatesActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  updates: [],
};

export const getUpdatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getUpdatesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      updates: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getUpdatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setUpdatesState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_UPDATES_SUCCESS]: getUpdatesSuccess,
  [Types.GET_UPDATES_FAILURE]: getUpdatesFailure,
  [Types.GET_UPDATES_REQUEST]: getUpdatesRequest,

  [Types.SET_UPDATES_STATE]: setUpdatesState,
});
