import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { EditProfileActions } from "redux-container/profile/EditProfileRedux";
import { call, put } from "redux-saga/effects";

export function* editProfile(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.editProfile, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.editProfileSuccess(response.data));
    } else {
      yield put(EditProfileActions.editProfileFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.editProfileFailure(error));
  }
}

export function* getCountries(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getCountries);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.getCountriesSuccess(response.data));
    } else {
      yield put(EditProfileActions.getCountriesFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.getCountriesFailure(error));
  }
}

export function* getStates(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getStates, action.countryCode);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.getStatesSuccess(response.data));
    } else {
      yield put(EditProfileActions.getStatesFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.getStatesFailure(error));
  }
}

export function* getCities(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.getCities,
      action.stateIsoCode,
      action.countryCode
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.getCitiesSuccess(response.data));
    } else {
      yield put(EditProfileActions.getCitiesFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.getCitiesFailure(error));
  }
}

export function* updateProfilePicture(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateProfilePicture, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.updateProfilePictureSuccess(response.data));
    } else {
      yield put(EditProfileActions.updateProfilePictureFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.updateProfilePictureFailure(error));
  }
}

export function* deleteProfilePicture(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteProfilePicture, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(EditProfileActions.deleteProfilePictureSuccess(response.data));
    } else {
      yield put(EditProfileActions.deleteProfilePictureFailure(response.data));
    }
  } catch (error) {
    yield put(EditProfileActions.deleteProfilePictureFailure(error));
  }
}
