import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getWatchListSuccess: ["response"],
  getWatchListFailure: ["error"],
  getWatchListRequest: ["data"],

  setWatchListState: ["key", "value"],
});

export const getWatchListTypes = Types;

export const getWatchListAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  watchListData: null,
  isSuccess: false,
  isDialogOpen: false,
};

export const getWatchListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getWatchListSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      watchListData: response?.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getWatchListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setWatchListState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_WATCH_LIST_SUCCESS]: getWatchListSuccess,
  [Types.GET_WATCH_LIST_FAILURE]: getWatchListFailure,
  [Types.GET_WATCH_LIST_REQUEST]: getWatchListRequest,

  [Types.SET_WATCH_LIST_STATE]: setWatchListState,
});
