import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";

export function* pageManagementSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPageAction.getPagesSuccess(response.data));
    } else {
      yield put(getPageAction.getPagesFailure(response.data));
    }
  } catch (error) {
    yield put(getPageAction.getPagesFailure(error));
  }
}

export function* promisePageSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPageAction.getPromiseSuccess(response.data));
    } else {
      yield put(getPageAction.getPromiseFailure(response.data));
    }
  } catch (error) {
    yield put(getPageAction.getPromiseFailure(error));
  }
}

export function* getProfilePageData(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPageAction.getProfilePageSuccess(response.data));
    } else {
      yield put(getPageAction.getProfilePageFailure(response.data));
    }
  } catch (error) {
    yield put(getPageAction.getProfilePageFailure(error));
  }
}
