import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  //get all category
  getAllCategoriesSuccess: ["response"],
  getAllCategoriesFailure: ["error"],
  getAllCategoriesRequest: ["data"],

  // get Profile Details
  getProfileDetailsSuccess: ["response"],
  getProfileDetailsFailure: ["error"],
  getProfileDetailsRequest: [""],

  //get Faqs
  getFaqsListSuccess: ["response"],
  getFaqsListFailure: ["error"],
  getFaqsListRequest: ["data"],

  // Edit Profile User Settings
  editProfileUserSettingsSuccess: ["response"],
  editProfileUserSettingsFailure: ["error"],
  editProfileUserSettingsRequest: ["data"],

  // Get User Account Details
  getMyAccountDetailsSuccess: ["response"],
  getMyAccountDetailsFailure: ["error"],
  getMyAccountDetailsRequest: [""],

  // Logout Current Device
  logoutSuccess: ["response"],
  logoutFailure: ["error"],
  logoutRequest: [""],

  // Logout from all Devices
  logoutAllSuccess: ["response"],
  logoutAllFailure: ["error"],
  logoutAllRequest: [""],

  // share feedback
  shareFeedbackSuccess: ["response"],
  shareFeedbackFailure: ["error"],
  shareFeedbackRequest: ["data"],

  setProfileState: ["key", "value"],
});

export const ProfileTypes = Types;

export const ProfileAction = Creators;

export const INITIAL_STATE = {
  categories: [],
  faqsData: [],
  profileDetails: {},
  myAccountDetails: {},
  error: null,
  isLoading: false,
  isSuccess: false,
  message: null,
  openSuccessDialog: false
};

/* Get Categories */
export const getAllCategoriesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getAllCategoriesSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      categories: response,
      isLoading: false,
    };
  }
};

export const getAllCategoriesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// get faqs list
export const getFaqsListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getFaqsListSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      faqsData: response,
      isLoading: false,
    };
  }
};

export const getFaqsListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// get Profile Details
export const getProfileDetailsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProfileDetailsSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      profileDetails: response,
      isLoading: false,
    };
  }
};

export const getProfileDetailsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Edit Profile User Settings
export const editProfileUserSettingsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const editProfileUserSettingsSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  }
};

export const editProfileUserSettingsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Logout Current Device
export const logoutRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const logoutSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  }
};

export const logoutFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Logout From All Device
export const logoutAllRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const logoutAllSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  }
};

export const logoutAllFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};


// Get My Account Details
export const getMyAccountDetailsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMyAccountDetailsSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      myAccountDetails: response,
      isLoading: false,
    };
  }
};

export const getMyAccountDetailsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};


//share feedback
export const shareFeedbackRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
    openSuccessDialog: false
  };
};

export const shareFeedbackSuccess = (state: any, action: any) => {
  const response = action?.response?.data;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isSuccess: true,
      openSuccessDialog: true
    };
  }
};

export const shareFeedbackFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
    isSuccess: false,
    openSuccessDialog: false
  };
};

// set Profile state
export const setProfileState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_CATEGORIES_REQUEST]: getAllCategoriesRequest,
  [Types.GET_ALL_CATEGORIES_SUCCESS]: getAllCategoriesSuccess,
  [Types.GET_ALL_CATEGORIES_FAILURE]: getAllCategoriesFailure,

  [Types.GET_FAQS_LIST_REQUEST]: getFaqsListRequest,
  [Types.GET_FAQS_LIST_SUCCESS]: getFaqsListSuccess,
  [Types.GET_FAQS_LIST_FAILURE]: getFaqsListFailure,

  [Types.GET_PROFILE_DETAILS_REQUEST]: getProfileDetailsRequest,
  [Types.GET_PROFILE_DETAILS_SUCCESS]: getProfileDetailsSuccess,
  [Types.GET_PROFILE_DETAILS_FAILURE]: getProfileDetailsFailure,

  [Types.EDIT_PROFILE_USER_SETTINGS_REQUEST]: editProfileUserSettingsRequest,
  [Types.EDIT_PROFILE_USER_SETTINGS_SUCCESS]: editProfileUserSettingsSuccess,
  [Types.EDIT_PROFILE_USER_SETTINGS_FAILURE]: editProfileUserSettingsFailure,

  [Types.GET_MY_ACCOUNT_DETAILS_REQUEST]: getMyAccountDetailsRequest,
  [Types.GET_MY_ACCOUNT_DETAILS_SUCCESS]: getMyAccountDetailsSuccess,
  [Types.GET_MY_ACCOUNT_DETAILS_FAILURE]: getMyAccountDetailsFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,

  [Types.LOGOUT_ALL_REQUEST]: logoutAllRequest,
  [Types.LOGOUT_ALL_SUCCESS]: logoutAllSuccess,
  [Types.LOGOUT_ALL_FAILURE]: logoutAllFailure,

  [Types.SHARE_FEEDBACK_REQUEST]: shareFeedbackRequest,
  [Types.SHARE_FEEDBACK_SUCCESS]: shareFeedbackSuccess,
  [Types.SHARE_FEEDBACK_FAILURE]: shareFeedbackFailure,

  [Types.SET_PROFILE_STATE]: setProfileState,
});
