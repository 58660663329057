import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  generateOtpSuccess: ["response"],
  generateOtpFailure: ["error"],
  generateOtpRequest: ["data"],

  setGenerateOtpState: ["key", "value"],
  resetGenerateOtpState: [""],
});

export const GenerateOtpTypes = Types;

export default Creators; // check creators logic in changepassword admin app

export const INITIAL_STATE = {
  error: null,
  isLoggedIn: false,
  isLoading: false,
  isOtpGenerated: false,
  isWhatsappCommunicationAllowed: false,
  mobileNumber: "",
};

export const generateOtpRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const generateOtpSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      isOtpGenerated: true,
      error: null,
      isLoading: false,
    };
  }
};

export const generateOtpFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setGenerateOtpState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const resetGenerateOtpState = (state: any, action: any) => {
  return {
    ...state,
    error: null,
    isLoggedIn: false,
    isLoading: false,
    isOtpGenerated: false,
    isWhatsappCommunicationAllowed: false,
    mobileNumber: "",
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GENERATE_OTP_SUCCESS]: generateOtpSuccess,
  [Types.GENERATE_OTP_FAILURE]: generateOtpFailure,
  [Types.GENERATE_OTP_REQUEST]: generateOtpRequest,

  [Types.SET_GENERATE_OTP_STATE]: setGenerateOtpState,
  [Types.RESET_GENERATE_OTP_STATE]: resetGenerateOtpState,
});
