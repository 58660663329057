import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getTestimonialsSuccess: ["response"],
  getTestimonialsFailure: ["error"],
  getTestimonialsRequest: ["data"],

  setTestimonialsState: ["key", "value"],
});

export const TestimonialsTypes = Types;

export const TestimonialsActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  testimonials: [],
};

export const getTestimonialsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getTestimonialsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      testimonials: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getTestimonialsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setTestimonialsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TESTIMONIALS_SUCCESS]: getTestimonialsSuccess,
  [Types.GET_TESTIMONIALS_FAILURE]: getTestimonialsFailure,
  [Types.GET_TESTIMONIALS_REQUEST]: getTestimonialsRequest,

  [Types.SET_TESTIMONIALS_STATE]: setTestimonialsState,
});
