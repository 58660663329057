import React from "react";
import styles from "./Loader.module.css";
import CircularProgress from "@mui/material/CircularProgress";

const Loader: React.FC = ({
  customHeight,
}: {
  customHeight?: number | string;
}) => {
  return (
    <div className={styles["loading-container"]}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loader;
