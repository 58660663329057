import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { NewLaunchActions } from "../../redux-container/investments/new-launch/NewLaunchRedux";
import { InvestmentDetailsActions } from "../../redux-container/investments/investment-details/InvestmentDetailsRedux";

import { call, put } from "redux-saga/effects";

export function* getNewLaunch(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NewLaunchActions.getNewLaunchSuccess(response.data));
    } else {
      yield put(NewLaunchActions.getNewLaunchFailure(response.data));
    }
  } catch (error) {
    yield put(NewLaunchActions.getNewLaunchFailure(error));
  }
}

export function* getAllInvestments(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllProjects, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NewLaunchActions.getAllInvestmentsSuccess(response.data));
    } else {
      yield put(NewLaunchActions.getAllInvestmentsFailure(response.data));
    }
  } catch (error) {
    yield put(NewLaunchActions.getAllInvestmentsFailure(error));
  }
}

export function* getMediaGallery(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getMediaGalleries, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(NewLaunchActions.getMediaGallerySuccess(response.data));
    } else {
      yield put(NewLaunchActions.getMediaGalleryFailure(response.data));
    }
  } catch (error) {
    yield put(NewLaunchActions.getMediaGalleryFailure(error));
  }
}

export function* getInvestmentDetails(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        InvestmentDetailsActions.getInvestmentDetailsSuccess(response.data)
      );
    } else {
      yield put(
        InvestmentDetailsActions.getInvestmentDetailsFailure(response.data)
      );
    }
  } catch (error) {
    yield put(InvestmentDetailsActions.getInvestmentDetailsFailure(error));
  }
}

export function* getWatchLists(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getWatchListData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InvestmentDetailsActions.getAllWatchlistSuccess(response.data));
    } else {
      yield put(InvestmentDetailsActions.getAllWatchlistFailure(response.data));
    }
  } catch (error) {
    yield put(InvestmentDetailsActions.getAllWatchlistFailure(error));
  }
}

export function* addToWatchlist(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addToWatchlist, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InvestmentDetailsActions.addToWatchlistSuccess(response.data));
    } else {
      yield put(InvestmentDetailsActions.addToWatchlistFailure(response.data));
    }
  } catch (error) {
    yield put(InvestmentDetailsActions.addToWatchlistFailure(error));
  }
}

export function* deleteWatchList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.removeFromWatchlist, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(InvestmentDetailsActions.deleteWatchlistSuccess(response.data));
    } else {
      yield put(InvestmentDetailsActions.deleteWatchlistFailure(response.data));
    }
  } catch (error) {
    yield put(InvestmentDetailsActions.deleteWatchlistFailure(error));
  }
}
