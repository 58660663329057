import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  referYourFriendSuccess: ["response"],
  referYourFriendFailure: ["error"],
  referYourFriendRequest: ["data"],

  setReferYourFriendState: ["key", "value"],
});

export const ReferYourFriendTypes = Types;

export const ReferYourFriendAction = Creators;

export const INITIAL_STATE = {
  error: "",
  isLoading: false,
  isSuccess: false,
  isResponseDialogOpen: false,
  isDialogOpen: false,
  message: "",
};

/*  get Promise Dashboard  */
export const referYourFriendRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const referYourFriendSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      isSuccess: true,
      error: "",
      isLoading: false,
      message: response.message,
    };
  }
};

export const referYourFriendFailure = (state: any, action: any) => {
  return {
    ...state,
    isSuccess: false,
    isLoading: false,
    error: action.error.message,
    message: "",
  };
};

export const setReferYourFriendState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REFER_YOUR_FRIEND_SUCCESS]: referYourFriendSuccess,
  [Types.REFER_YOUR_FRIEND_FAILURE]: referYourFriendFailure,
  [Types.REFER_YOUR_FRIEND_REQUEST]: referYourFriendRequest,

  [Types.SET_REFER_YOUR_FRIEND_STATE]: setReferYourFriendState,
});
