import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCustomerSearchDataSuccess: ["response"],
  getCustomerSearchDataFailure: ["error"],
  getCustomerSearchDataRequest: ["data"],

  getAdminSearchDataSuccess: ["response"],
  getAdminSearchDataFailure: ["error"],
  getAdminSearchDataRequest: ["data"],

  setSearchState: ["key", "value"],
});

export const SearchTypes = Types;

export const SearchActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  customerSearchData: null,
  adminSearchData: null
};

export const getCustomerSearchDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerSearchDataSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      customerSearchData: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getCustomerSearchDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};


// Get Admin Search Data
export const getAdminSearchDataRequest = (state: any) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  
  export const getAdminSearchDataSuccess = (state: any, action: any) => {
    const response = action.response;
  
    if (response) {
      return {
        ...state,
        adminSearchData: response.data,
        error: null,
        isLoading: false,
      };
    }
  };
  
  export const getAdminSearchDataFailure = (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      error: action.error.message,
    };
  };



export const setSearchState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CUSTOMER_SEARCH_DATA_SUCCESS]: getCustomerSearchDataSuccess,
  [Types.GET_CUSTOMER_SEARCH_DATA_FAILURE]: getCustomerSearchDataFailure,
  [Types.GET_CUSTOMER_SEARCH_DATA_REQUEST]: getCustomerSearchDataRequest,

  [Types.GET_ADMIN_SEARCH_DATA_SUCCESS]: getAdminSearchDataSuccess,
  [Types.GET_ADMIN_SEARCH_DATA_FAILURE]: getAdminSearchDataFailure,
  [Types.GET_ADMIN_SEARCH_DATA_REQUEST]: getAdminSearchDataRequest,

  [Types.SET_SEARCH_STATE]: setSearchState,

});
