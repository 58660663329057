import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPortfolioDetailsSuccess: ["response"],
  getPortfolioDetailsFailure: ["error"],
  getPortfolioDetailsRequest: ["data"],

  getPortfolioPreviewSuccess: ["response"],
  getPortfolioPreviewFailure: ["error"],
  getPortfolioPreviewRequest: ["data"],

  getInvestmentSummarySuccess: ["response"],
  getInvestmentSummaryFailure: ["error"],
  getInvestmentSummaryRequest: ["data"],

  getBookingJourneyRequest: ["data"],
  getBookingJourneySuccess: ["response"],
  getBookingJourneyFailure: ["error"],

  getProjectFaqsRequest: ["data"],
  getProjectFaqsSuccess: ["response"],
  getProjectFaqsFailure: ["error"],

  setPortfolioState: ["key", "value"],
});

export const getPortfolioTypes = Types;

export const getPortfolioAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  investmentDetail: null,
  portfolioData: null,
  investmentSummary: null,
  isSuccess: false,
  isDialogOpen: false,
  isDrawerOpen: false,
  bookingJourneyData: null,
  projectFaqs: null,
};

export const getPortfolioDetailsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPortfolioDetailsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      investmentDetail: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPortfolioDetailsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setPortfolioState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const getPortfolioPreviewRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPortfolioPreviewSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      portfolioData: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPortfolioPreviewFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const getInvestmentSummaryRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getInvestmentSummarySuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      investmentSummary: response.data,
      error: null,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

// Booking Journery
export const getBookingJourneyRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getBookingJourneySuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      bookingJourneyData:response.data,
      message: response.message,
      isLoading: false,
    };
  }
};

export const getBookingJourneyFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

//project faqs
export const getProjectFaqsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectFaqsSuccess = (
  state: any,
  action: any
) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isSuccess: true,
      projectFaqs:response.data,
      message: response.message,
      isLoading: false,
    };
  }
};

export const getProjectFaqsFailure = (
  state: any,
  action: any
) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};


export const getInvestmentSummaryFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PORTFOLIO_DETAILS_SUCCESS]: getPortfolioDetailsSuccess,
  [Types.GET_PORTFOLIO_DETAILS_FAILURE]: getPortfolioDetailsFailure,
  [Types.GET_PORTFOLIO_DETAILS_REQUEST]: getPortfolioDetailsRequest,

  [Types.GET_PORTFOLIO_PREVIEW_SUCCESS]: getPortfolioPreviewSuccess,
  [Types.GET_PORTFOLIO_PREVIEW_FAILURE]: getPortfolioPreviewFailure,
  [Types.GET_PORTFOLIO_PREVIEW_REQUEST]: getPortfolioPreviewRequest,

  [Types.GET_INVESTMENT_SUMMARY_SUCCESS]: getInvestmentSummarySuccess,
  [Types.GET_INVESTMENT_SUMMARY_FAILURE]: getInvestmentSummaryFailure,
  [Types.GET_INVESTMENT_SUMMARY_REQUEST]: getInvestmentSummaryRequest,

  [Types.GET_BOOKING_JOURNEY_SUCCESS]:
    getBookingJourneySuccess,
    [Types.GET_BOOKING_JOURNEY_FAILURE]:
    getBookingJourneyFailure,
    [Types.GET_BOOKING_JOURNEY_REQUEST]:
    getBookingJourneyRequest,

    [Types.GET_PROJECT_FAQS_SUCCESS]: getProjectFaqsSuccess,
    [Types.GET_PROJECT_FAQS_FAILURE]: getProjectFaqsFailure,
    [Types.GET_PROJECT_FAQS_REQUEST]: getProjectFaqsRequest,

  [Types.SET_PORTFOLIO_STATE]: setPortfolioState,
});
