import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPagesSuccess: ["response"],
  getPagesFailure: ["error"],
  getPagesRequest: ["data"],

  getPromiseSuccess: ["response"],
  getPromiseFailure: ["error"],
  getPromiseRequest: ["data"],

  getProfilePageSuccess: ["response"],
  getProfilePageFailure: ["error"],
  getProfilePageRequest: ["data"],

  setPageState: ["key", "value"],
});

export const getPageTypes = Types;

export const getPageAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  pageData: null,
  promiseDetails: null,
  promiseData: null,
  portfolioData: null,
  isSuccess: false,
  isDialogOpen: false,
  profilePageData: {},
};

export const getPagesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPagesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      pageData: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPagesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};
export const getPromiseRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPromiseSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPromiseFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setPageState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const getProfilePageRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProfilePageSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      profilePageData: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getProfilePageFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGES_SUCCESS]: getPagesSuccess,
  [Types.GET_PAGES_FAILURE]: getPagesFailure,
  [Types.GET_PAGES_REQUEST]: getPagesRequest,
  
  [Types.GET_PROMISE_SUCCESS]: getPromiseSuccess,
  [Types.GET_PROMISE_FAILURE]: getPromiseFailure,
  [Types.GET_PROMISE_REQUEST]: getPromiseRequest,

  [Types.GET_PROFILE_PAGE_SUCCESS]: getProfilePageSuccess,
  [Types.GET_PROFILE_PAGE_FAILURE]: getProfilePageFailure,
  [Types.GET_PROFILE_PAGE_REQUEST]: getProfilePageRequest,

  [Types.SET_PAGE_STATE]: setPageState,
});
