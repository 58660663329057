import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getNewLaunchSuccess: ["response"],
  getNewLaunchFailure: ["error"],
  getNewLaunchRequest: ["data"],

  getAllInvestmentsSuccess: ["response"],
  getAllInvestmentsFailure: ["error"],
  getAllInvestmentsRequest: ["data"],

  getMediaGallerySuccess: ["response"],
  getMediaGalleryFailure: ["error"],
  getMediaGalleryRequest: ["data"],

  setNewLaunchState: ["key", "value"],
});

export const NewLaunchTypes = Types;

export const NewLaunchActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  newLaunchData: null,
  isSeeAllNewLaunchDialogOpen: false,
  isDiscoverInvestmentsDialogOpen: false,
  isLastFewPlotsDialogOpen: false,
  isTrendingProjectDialogOpen: false,
  galleries: [],
  investments: [],
};

export const getNewLaunchRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getNewLaunchSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      newLaunchData: response.data,
      isLoading: false,
    };
  }
};

export const getNewLaunchFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const getAllInvestmentsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getAllInvestmentsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      investments: response.data,
      isLoading: false,
    };
  }
};

export const getAllInvestmentsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const getMediaGalleryFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const getMediaGalleryRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMediaGallerySuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      galleries: response.data,
      isLoading: false,
    };
  }
};

export const setNewLaunchState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NEW_LAUNCH_REQUEST]: getNewLaunchRequest,
  [Types.GET_NEW_LAUNCH_SUCCESS]: getNewLaunchSuccess,
  [Types.GET_NEW_LAUNCH_FAILURE]: getNewLaunchFailure,

  [Types.GET_ALL_INVESTMENTS_REQUEST]: getAllInvestmentsRequest,
  [Types.GET_ALL_INVESTMENTS_SUCCESS]: getAllInvestmentsSuccess,
  [Types.GET_ALL_INVESTMENTS_FAILURE]: getAllInvestmentsFailure,

  [Types.GET_MEDIA_GALLERY_REQUEST]: getMediaGalleryRequest,
  [Types.GET_MEDIA_GALLERY_SUCCESS]: getMediaGallerySuccess,
  [Types.GET_MEDIA_GALLERY_FAILURE]: getMediaGalleryFailure,

  [Types.SET_NEW_LAUNCH_STATE]: setNewLaunchState,
});
