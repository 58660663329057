import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

export function* addTroubleSignin(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.troubleSigninCase, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(TroubleSigninAction.addTroubleSigninSuccess(response.data));
    } else {
      yield put(TroubleSigninAction.addTroubleSigninFailure(response.data));
    }
  } catch (error) {
    yield put(TroubleSigninAction.addTroubleSigninFailure(error));
  }
}
